import React from "react";

function Home() {
    return (
        <div className="homeBackgroundColor">
            <div className="homeImage">
                <img src="dish1-1000.jpg" alt="home_sushi" width="100%" height="auto"></img>
            </div>
        </div>
    );
}

export default Home;